// LocationSearch.js

import React, { useState, useEffect } from 'react';
import './css/Test.css';
import Spinner from "./Spinner";

function Test() {
  const [code, setCode] = useState('');
  const [locationType, setLocationType] = useState('all');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Simulate an API call with dummy test data
    const dummyData = [
      { name: 'Starbucks Location 1', address: '123 Main St' },
      { name: 'Starbucks Location 2', address: '456 Elm St' },
      { name: 'Starbucks Location 3', address: '789 Oak St' },
    ];

    setIsLoading(true);

    // Simulate a delay for the loading effect
    setTimeout(() => {
      setResults(dummyData);
      setIsLoading(false);
    }, 1000); // Adjust the delay as needed
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., make an API call)
  };

  return (
    <div className="starbucks">

<div className="search-container-large"> {/* Adjusted class name for a larger box */}
      <h2>Enter the Pledges</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="code">Enter QR Codes or Paddle Numbers:</label>
        <input
          type="text"
          id="code"
          name="code"
          placeholder="QR Code or Paddle Number"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
        />

        <label htmlFor="location-type">Dollar Amount:</label>
        <select
          id="location-type"
          name="location-type"
          value={locationType}
          onChange={(e) => setLocationType(e.target.value)}
        >
          <option value="all">All</option>
          <option value="cafe">Cafe</option>
          <option value="drive-thru">Drive-Thru</option>
          <option value="reserve">Reserve</option>
          {/* Add more options as needed */}
        </select>

        <button type="submit">Search</button>
      </form>

      {isLoading && <p>Loading...</p>}

      {results.length > 0 && (
        <div className="results">
          <h3>Previous Pledges</h3>
          <ul>
            {results.map((result, index) => (
              <li key={index}>{result.name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
    <Spinner />

    </div>
  );

}

export default Test;
