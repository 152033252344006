import React, { Component } from "react";
import LogoHeader from "./LogoHeader";
import "chart.js/auto";
import "../css/Dashboard.css"; // Create a separate CSS file for styling
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import SummaryText from "./SummaryText";
import TicketSales from "./TicketSales";
import axios from "axios";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const isTest= window.location.hostname.includes('test.');

    this.state = {
      progress: 0, // Initial progress value
      amount: 0,
      countProgress: 0, // Initial progress value
      total_amount: 0,
      progress_data: {
        datalabels: {
          // Configure data labels for circular progress bar
          align: "left",
          text: "We are delighted to announce that the Soiree has received an incredible total of pledges, far exceeding our expectations.",
        },
      },
      pie_data: {},
      options: {},
      screenWidth: 0,
      count: 0,
      allAttendeesTotal: 0,
      titleSuffix: isTest ? <> [Test Staff Only]</> :<> [Staff Only]</>
    };
  }




  startProgress = (total) => {
    let interval = setInterval(() => {
      if (this.state.progress < 100) {
        this.setState((prevState) => ({
          progress: prevState.progress + 1,
          amount: (prevState.progress + 1) * total * 0.01,
        }));
      } else {
        clearInterval(interval); // Stop when progress reaches 100%
      }
    }, 70); // Adjust the interval for your needs

    return interval;
  };

  startCountProgress = (total) => {
    let interval = setInterval(() => {
      if (this.state.countProgress < 100) {
        this.setState((prevState) => ({
          countProgress: prevState.progress + 1,
          count: Math.round((prevState.progress + 1) * total * 0.01),
        }));
      } else {
        clearInterval(interval); // Stop when progress reaches 100%
      }
    }, 70); // Adjust the interval for your needs

    return interval;
  };

  generateComplementaryColors = (count, baseColor) => {
    const colors = [];
    const baseHSL = this.rgbToHsl(baseColor);

    for (let i = 0; i < count; i++) {
      const hue = (baseHSL.h + (i * 180) / count) % 360;
      const hexColor = this.hslToHex({ h: hue, s: baseHSL.s, l: baseHSL.l });
      colors.push(hexColor);
    }

    return colors;
  };

  rgbToHsl = (rgbColor) => {
    const r = rgbColor.r / 255;
    const g = rgbColor.g / 255;
    const b = rgbColor.b / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return { h: h * 360, s: s, l: l };
  };

  hslToHex = (hslColor) => {
    const { h, s, l } = hslColor;
    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;
    const rgb = this.hslToRgb(h, c, x, m);
    return `#${this.rgbToHex(rgb.r)}${this.rgbToHex(rgb.g)}${this.rgbToHex(
      rgb.b
    )}`;
  };

  hslToRgb = (h, c, x, m) => {
    let r, g, b;
    if (h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }
    return { r: (r + m) * 255, g: (g + m) * 255, b: (b + m) * 255 };
  };

  rgbToHex = (value) => {
    const hex = Math.round(value).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  };

  componentDidMount() {
    this.handleResize = () => {
      this.setState({ screenWidth: window.innerWidth });
    };

    // Add an event listener to update the screenWidth when the window is resized
    window.addEventListener("resize", this.handleResize);

    // Fetch data for the circular progress bar
    fetch(`${process.env.REACT_APP_API_URL}/tickets-total`)
      .then((response) => response.json())
      .then((data) => {
        this.startProgress(data.Total);
      })
      .catch((error) => console.error("Error fetching progress data: ", error));



      const fetchAttendees = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/attendees/all`);
          const attendees = response.data;
          const allAttendeesTotal = attendees.length;
          const completeAttendees = attendees.filter(attendee => attendee.isRegistrationComplete === 1);
          const registeredTotal = completeAttendees.length;

          this.setState({allAttendeesTotal});

          this.startCountProgress(registeredTotal);
        } catch (error) {
          console.error('Error fetching attendees:', error);
        }
      };

      fetchAttendees();

  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("resize", this.handleResize);
  }

  getInspiringQuote = (amount) => {
    const quotes = [
      "When you give, you get so much in return. It's the best investment you can make. — Oprah Winfrey",
      "Every time you have to raise money, it's a reminder that you are doing something important. — Mary Frances Bowley",
      "Fundraising is the gentle art of teaching the joy of giving. — Hank Rosso",
      "The best way to make a difference in the world is to start by making a difference in your own community. — Marian Wright Edelman",
      "Fundraising is the purest form of love. — Nita Lowey",
      "We make a living by what we get, but we make a life by what we give. — Winston Churchill",
      "Don't raise your voice, improve your argument. — Desmond Tutu",
      "To know even one life has breathed easier because you have lived. This is to have succeeded. — Bessie Anderson Stanley",
      "When we give cheerfully and accept gratefully, everyone is blessed. — Maya Angelou",
      "Those who are happiest are those who do the most for others. - Booker T. Washington",
      "The meaning of life is to find your gift. The purpose of life is to give it away. - Pablo Picasso",
      "I don't think you ever stop giving. I really don't. I think it's an on-going process. - Oprah Winfrey",
      "You can have everything in life you want if you will just help enough other people get what they want. - Zig Ziglar",
      "The wise man does not lay up his own treasures. The more he gives to others, the more he has for his own. - Lao Tzu",
      "The smallest act of kindness is worth more than the grandest intention. - Oscar Wilde",
      "The best antidote I know for worry is work. The best cure for weariness is the challenge of helping someone who is even more tired. One of the great ironies of life is this: He or she who serves almost always benefits more than he or she who is served. - Gordon B. Hinckley",
      "It's not enough to have lived. We should be determined to live for something. - Winston Churchill",
      "Charity sees the need, not the cause. - German Proverb",
      "The most effective way to do it, is to do it. - Amelia Earhart",
      "Don't wait for other people to be loving, giving, compassionate, grateful, forgiving, generous, or friendly... lead the way! - Steve Maraboli",
      "To love and be loved is to feel the sun from both sides. - David Viscott",
      "We rise by lifting others. - Robert Ingersoll",
      "Giving is not just about making a donation. It is about making a difference. - Kathy Calvin",
      "Every sunrise is an invitation for us to arise and brighten someone's day. - Richelle E. Goodrich",
      "The best way to find yourself is to lose yourself in the service of others. - Mahatma Gandhi",
      "You don't make progress by standing on the sidelines, whimpering and complaining. You make progress by implementing ideas. - Shirley Chisholm",
      "What we do for ourselves dies with us. What we do for others and the world remains and is immortal. - Albert Pine",
      "Success is not final, failure is not fatal: It is the courage to continue that counts. - Winston Churchill",
      "If you think you are too small to be effective, you have never been in bed with a mosquito. - Betty Reese",
      "You can't live a perfect day without doing something for someone who will never be able to repay you. - John Wooden",
      "Service to others is the rent you pay for your room here on Earth. - Muhammad Ali",
      "The most beautiful thing is that we are not waiting for someone else to step up; we are stepping up ourselves. - Malala Yousafzai",
      "Giving back is as good for you as it is for those you are helping because giving gives you purpose. When you have a purpose-driven life, you're a happier person. - Goldie Hawn",
      "You have two hands. One to help yourself, the second to help others. - Audrey Hepburn"
    ];

    // Map the dollar amount to an index in the quotes array
    let index = Math.floor((amount / 5000) * quotes.length);

    // Ensure the index is within the bounds of the array
    index %= quotes.length;

    // Combine the selected quote
    const inspiringQuote = `${quotes[index]}`;

    return inspiringQuote;
};


getCongratsMessage = (amount) => {
  // Format the amount to 2 decimal places
  const formattedAmount = amount.toFixed(2);

  // Add a personal congratulations message
  const congratulationsMessage = `Congratulations to Big Sisters of BC Lower Mainland for raising $${formattedAmount}!`;

  return congratulationsMessage;
};


  render() {
    const isMobile = this.state.screenWidth <= 768;

    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Soirée 2024</span>{this.state.titleSuffix}
            </h1>
            <div className="auth-wrap">
              <div className="auth-col facts-i">
                <h3 className="facts-i-ttl">Total Money Raised From Ticket Sales</h3>
                <div
                  id="facts-i-percent-4"
                  data-num="0.7"
                  className="facts-i-percent"
                  style={{ width: "325px", height: "325px" }}
                >
                  <CircularProgressbar
                    value={this.state.progress}
                    styles={buildStyles({
                      textColor: "#000", // Text color
                      pathColor: "#2dccd3", // Progress bar color
                      trailColor: "#ccc", // Trail color
                    })}
                    options={this.state.progress_data}
                  />
                  {/* Custom text positioning */}
                  <div
                    style={{
                      position: "absolute",
                      top: "50%", // Center vertically
                      left: "50%", // Center horizontally
                      transform: "translate(-50%, -50%)", // Center both horizontally and vertically
                    }}
                  >
                    {this.state.amount.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </div>
                </div>
                <p className="congratulations-message">
                  {this.getCongratsMessage(this.state.amount)}
                </p>
                <p className="inspiring-quote">
                  {this.getInspiringQuote(this.state.total_amount)}
                </p>
              </div>

              <div className="auth-col facts-i">
                <h3 className="facts-i-ttl">Total Number of People Registered for Soirée 2024</h3>
                <div
                  id="facts-i-percent-4"
                  data-num="0.7"
                  className="facts-i-percent"
                  style={{ width: "325px", height: "325px" }}
                >
                  <CircularProgressbar
                    value={this.state.countProgress}
                    styles={buildStyles({
                      textColor: "#000", // Text color
                      pathColor: "#fcb61c", // Progress bar color
                      trailColor: "#ccc", // Trail color
                    })}
                    options={this.state.progress_data}
                  />
                  {/* Custom text positioning */}
                  <div
                    style={{
                      position: "absolute",
                      top: "50%", // Center vertically
                      left: "50%", // Center horizontally
                      transform: "translate(-50%, -50%)", // Center both horizontally and vertically
                    }}
                  >
                    {this.state.count}/{this.state.allAttendeesTotal}
                  </div>
                </div>
              </div>

            </div>


          </section>

          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Ticket Sales Summary</span>
            </h1>
            <div className="auth-wrap">
              <SummaryText />
            </div>
          </section>

        </main>
      </>
    );
  }
}

export default Dashboard;
