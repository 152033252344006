import React, { Component } from "react";
import overlayImage from "../img/foreground.png"; // Import your PNG image
import videoBackground from "../img/Sequence 01.mp4"; // Import the video file
import "../css/Home.css"; // Create a separate CSS file for styling
import videoBackground2 from "../img/Sequence 02.mp4"; // Import the video file
import Spinner from "../Spinner";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      isVisible: false,
      dataList: [],
      amount: 10000,
      isLoading: true
    };
  }

  componentDidMount() {
    const checkForVideo = () => {
      const videoBackgroundElement = document.querySelector('.video-background');

      const canPlayThroughHandler = () => {
        this.setState({ isLoading: false }, () => {
          this.updateScreen();
  
          // Set up an interval to call the API every 4 seconds
          this.interval = setInterval(this.updateScreen, 4000);
        });
  
        // Remove the event listener after it has been triggered
        videoBackgroundElement.removeEventListener('canplaythrough', canPlayThroughHandler);
      };

      if (videoBackgroundElement) {
        // Check if the video can play through without buffering
        videoBackgroundElement.addEventListener('canplaythrough', canPlayThroughHandler);
      }
      else {
        setTimeout(checkForVideo, 1000);
      }
    };

    checkForVideo();
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to prevent memory leaks
    clearInterval(this.interval);
    clearTimeout(this.timeoutId);
  }

  generateUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }

  updateScreen = () => {
    if (this.state.dataList.length === 0) {
      this.fetchData();
    } else {
      this.processList();
    }
  };

  fetchData = () => {
    console.log("Fetching data!");
    // Check if the unique identifier exists in sessionStorage; if not, generate one
    let uniqueId = sessionStorage.getItem("uniqueId");
    if (!uniqueId) {
      uniqueId = this.generateUniqueId();
      sessionStorage.setItem("uniqueId", uniqueId);
    }

    fetch(
      `${process.env.REACT_APP_API_URL}/brenda/bids?uniqueId=${uniqueId}`
    )
      .then((response) => response.json())
      .then((dataList) => {
        // Check if dataList is an array
        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            let myDataList = [...dataList];
            myDataList.sort((a, b) => b.Amount - a.Amount);

            this.setState({ dataList: myDataList });
          }
        }
      });
  };

  processList = () => {
    const data = this.state.dataList[0];
    const newName = data.Name;
    const newAmount = data.Amount;

    // Update the content of the name state with the fetched name
    this.setState({ name: newName, amount: newAmount, isVisible: true });

    // Add a delay to make the text visible
    setTimeout(() => {
      console.log("Setting Invisible");
      this.setState({ isVisible: false });
    }, 3000); // Adjust this delay as needed

    const [, ...newArray] = this.state.dataList; // Destructure the array, leaving out the first item
    this.setState({ dataList: newArray });
  };

  render() {
    return (
      <div className="background">
        <div className="home">
          <video
            className="video-background"
            autoPlay
            loop
            muted
            style={{ width: "100%", height: "100%" }}
          >
            <source
              src={
                this.state.amount >= 10000 ? videoBackground2 : videoBackground
              }
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <h1
            className={`name ${
              this.state.isVisible ? "" : "fade-out"
            } larger-font`}
          >
            {this.state.name}
          </h1>
          <div className="overlay">
            <img src={overlayImage} alt="Overlay" />
          </div>
        </div>
        {this.state.isLoading && (<Spinner />)}
      </div>
    );
  }
}

export default Home;