import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import "jspdf-autotable"; // Import jsPDF autotable for table formatting
import "../css/Reports.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import jsPDF from "jspdf";
import { Roboto } from "../fonts/roboto";
import html2canvas from "html2canvas";
import { format } from "date-fns";
import addChecklist from "./addChecklist";
import logo from "../img/logo.png";
import RibonBackgroundBlue from "../img/ribbonbackgroundblue.png";
import { PDFDocument } from "pdf-lib";
import Spinner from "../Spinner";

class PDFObject {
  constructor(pdf, index) {
    this.pdf = pdf;
    this.index = index;
  }
}

class Report {
  constructor(index, title, data, headers) {
    this.index = index;
    this.title = title;
    this.data = data;
    this.headers = headers;
  }
}

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      donationType: "",
      customAmount: "",
      dollarAmount: "",
      error: null,
      searchResults: [],
      data: [],
      pdfWorking: false,
      roboto: new Roboto(),
      asOfDate: Date.now(),
      pdfList: [],
      reports: [],
      title: "",
      categories: []
    };

    this.reportCount = 0;
  }

  waitForElm = async (selector) => {
    return new Promise((resolve) => {
      const checkElement = () => {
        const target = document.querySelector(selector);
        if (target) {
          resolve(target);
        }
      };

      // Check if the element is already present
      checkElement();

      // If not, set up a MutationObserver to wait for it
      const observer = new MutationObserver((mutations) => {
        checkElement();
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  addLetterhead = async (pdf, report, index) => {
    console.log("Add Letterhead");
    const elm = await this.waitForElm("#pdfLetterhead");
    elm.style.display = "block";

    console.log("Found element");
    console.log(elm);

    const canvas = await html2canvas(elm);

    const fileWidth = 216;
    const fileHeight = (canvas.height * fileWidth) / canvas.width;
    const fileURI = canvas.toDataURL("image/jpeg", 1.0);

    pdf.addImage(fileURI, "JPEG", 0, 0, fileWidth, fileHeight, "", "FAST");

    pdf.setTextColor("white");
    pdf.setFontSize(38);
    pdf.setFont("Roboto", "normal");
    let title = "";

    title += report.title.toUpperCase();

    pdf.text(title, 2, 30);

    pdf.setFont("Roboto", "bold");
    pdf.setFontSize(10);
    const formattedDate = format(this.state.asOfDate, "MMMM, dd yyyy");
    if (formattedDate) {
      pdf.text("AS OF " + formattedDate.toUpperCase(), 2, 35);
    }

    pdf.setFont("Roboto", "normal");

    pdf.setFontSize(8);
    pdf.setTextColor("black");
    pdf.text(
      "- Page " + (index + 1).toString() + " -",
      Math.round(fileWidth / 2) - 6,
      fileHeight - 10
    );

    const logo = document.getElementById("hospitalLogo");
    if (logo) {
      logo.style.display = "block";
    }

    const elm2 = await this.waitForElm("#hospitalLogo");

    addChecklist(pdf, report);

    pdf.addImage(elm2, "PNG", 147, 254, 68, 25, "", "FAST");

    elm2.style.display = "none";

    elm.style.display = "none";
  };

  generateUniqueId = () => {
    const timestamp = new Date().getTime(); // Get the current timestamp in milliseconds
    const random = Math.floor(Math.random() * 1000000); // Generate a random number between 0 and 999999

    // Combine the timestamp and random number to create the barcode ID
    const barcodeId = `${timestamp}${random}`;

    return barcodeId;
  };

  fetchData = () => {
    console.log("Fetching data!");
    // Check if the unique identifier exists in sessionStorage; if not, generate one
    let uniqueId = sessionStorage.getItem("uniqueId");
    if (!uniqueId) {
      uniqueId = this.generateUniqueId();
      sessionStorage.setItem("uniqueId", uniqueId);
    }

    fetch(`${process.env.REACT_APP_API_URL}/brenda/bids?uniqueId=${uniqueId}`)
      .then((response) => response.json())
      .then((dataList) => {
        // Check if dataList is an array
        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            let myDataList = [...dataList];
            myDataList.sort((a, b) => b.Amount - a.Amount);

            this.setState({ searchResults: myDataList });
          }
        }
      });
  };

  fetchAttendees = () => {
    console.log("Fetching data!");
    // Check if the unique identifier exists in sessionStorage; if not, generate one
    let uniqueId = sessionStorage.getItem("uniqueId");
    if (!uniqueId) {
      uniqueId = this.generateUniqueId();
      sessionStorage.setItem("uniqueId", uniqueId);
    }

    fetch(`${process.env.REACT_APP_API_URL}/brenda/attendees`)
      .then((response) => response.json())
      .then((dataList) => {
        // Check if dataList is an array
        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            let myDataList = [...dataList];

            this.setState({ data: myDataList });
          }
        }
      });
  };

  fetchWine = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/brenda/wines`);
      const dataList = await response.json();

      console.log(dataList);

      if (Array.isArray(dataList)) {
        return dataList;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed, e.g., throw an exception or return a default value.
    }
  };

  fetchCategories = async () => {
    fetch(`${process.env.REACT_APP_API_URL}/brenda/pledgeCategories`)
      .then((response) => response.json())
      .then((dataList) => {
        // Check if dataList is an array
        if (Array.isArray(dataList)) {
          if (dataList.length !== 0) {
            let myDataList = [...dataList];

            this.setState({ categories: myDataList });
          }
        }
      });
  };

  componentDidMount() {
    this.setState({pdfWorking: true}, ()=> {
      this.fetchData();
      this.fetchAttendees();
      this.fetchCategories();
      this.setState({pdfWorking:false})
    });

  }

  handleDonationChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ dollarAmount: selectedValue, donationType: selectedValue });

    console.log(selectedValue);

    // If "Custom Amount" is selected, clear the custom amount field
    if (selectedValue === "custom") {
      this.setState({ customAmount: "" });
    }
  };

  generateBasePDF = () => {
    const pdf = new jsPDF("p", "mm", "letter", true);
    pdf.addFileToVFS("Roboto-Regular.ttf", this.state.roboto.normal);
    pdf.addFont("Roboto-Regular.ttf", "Roboto", "normal");

    pdf.addFileToVFS("Roboto-Bold.ttf", this.state.roboto.bold);
    pdf.addFont("Roboto-Bold.ttf", "Roboto", "bold");

    return pdf;
  };

  generateReport = async (pdf, report, index) => {
    console.log("We are generating reports: " + index);
    await this.addLetterhead(pdf, report, index);
  };

  mergePdfs = async (pdfsToMerge) => {
    const mergedPdf = await PDFDocument.create();
    const actions = pdfsToMerge.map(async (pdfBuffer) => {
      const pdf = await PDFDocument.load(pdfBuffer);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    });
    await Promise.all(actions);
    const mergedPdfFile = await mergedPdf.save();
    return mergedPdfFile;
  };

  savePDF = (pdf, index) => {
    
    const pdfObject = new PDFObject(pdf.output("arraybuffer"), index);
    this.setState(
      (prevState) => ({
        pdfList: [...prevState.pdfList, pdfObject],
      }), () => {
        this.reportCount++;
        if (this.reportCount === this.state.reports.length) {
          this.reportCount = 0;
    
          this.state.pdfList.sort((a, b) => {
            const x = a.index;
            const y = b.index;
            if (x < y) return -1;
            if (x > y) return 1;
            return 0;
          });
    
          const pdfs = this.state.pdfList.map((a) => a.pdf);
    
          this.mergePdfs(pdfs).then((finalPDF) => {
            this.setState({
              pdfList: [],
              pdfWorking: false,
              dollarAmount: "",
              donationType: "",
            }, ()=> {
              const file = new Blob([finalPDF], { type: "application/pdf" });
              const fileURL = window.URL.createObjectURL(file);
              const anchor = document.createElement("a");
              console.log(this.state);
              anchor.download = this.state.title;
              anchor.href = fileURL;
              anchor.click();
              anchor.remove();
            });
          });
        }
      });
    };

  createReports = async () => {
    console.log(this.state.reports);

    this.state.reports.forEach(async (report, index) => {
      const pdf = this.generateBasePDF();

      await this.generateReport(pdf, report, index);

      this.savePDF(pdf, index);
    });
  };

  getData = () => {
    const donationOptions = [
      "25000",
      "20000",
      "15000",
      "10000",
      "7500",
      "5000",
      "2500",
      "500",
      "100",
      "50",
    ];

    const { donationType, searchResults } = this.state;

    // Filter pledges based on donationType
    let filteredPledges = [];

    if (donationType === "custom") {
      // Convert the donationOptions array values to integers
      const optionValues = donationOptions.map((value) => parseInt(value, 10));

      filteredPledges = searchResults.filter(
        (pledge) => !optionValues.includes(parseInt(pledge.Amount, 10))
      );
    } else {
      filteredPledges = searchResults.filter(
        (pledge) => pledge.Amount === parseInt(donationType, 10)
      );
    }

    // Group pledges by Table Number
    const groupedPledges = {};
    filteredPledges.forEach((pledge) => {
      const tableNumber = pledge["Table Number"];
      if (!groupedPledges[tableNumber]) {
        groupedPledges[tableNumber] = [];
      }
      groupedPledges[tableNumber].push(pledge);
    });

    return groupedPledges;
  };

  downloadReport = () => {
    const groupedPledges = this.getData();
    const reports = [];
    const headers = ["Paddle Number", "Name", "Amount", "Contact"];
    let index = 0;

    Object.entries(groupedPledges).forEach(([tableNumber, pledgesForTable]) => {
      const cleaned_data = [];

      pledgesForTable.map(my_pledge => {
        const phoneNumber = my_pledge["Phone Number"];
        const email = my_pledge["Email"];

        cleaned_data.push({
          "Paddle Number": my_pledge["Paddle Number"],
          "Name": my_pledge["Name"],
          "Amount": my_pledge["Amount"].toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
          Contact: `${phoneNumber}\n${email}`,
          "Checked": my_pledge["isPaid"]
        });
        
      });

      const report = new Report(
        index,
        `Table #${tableNumber}`,
        cleaned_data,
        headers
      );
      reports.push(report);
      index++;
    });

    console.log(reports);

    if (reports.length === 0) {
      this.setState({
        error: "No pledges were given for the chosen pledge amount",
        donationType: "",
        pdfWorking: false
      });
    } else {
      this.setState(
        {
          pdfWorking: true,
          reports: reports,
          title: `${new Date().toISOString().split("T")[0]}_${
            this.state.donationType
          }_PledgeReport.pdf`,
        },
        () => {
          this.createReports();
        }
      );
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({pdfWorking: true});

    if (this.state.dollarAmount === "") {
      this.setState({ error: "Please select a pledge amount", pdfWorking: false });
      return;
    } else {
      this.setState({ error: "" });

      // Call downloadReport to generate and download the PDF
      this.downloadReport();
    }
  };

  handleWineSubmit = async (e) => {
    e.preventDefault();
    this.setState({pdfWorking: true});

    const wine = await this.fetchWine();

    this.setState({ wine: wine }, () => {
      const reports = [];
      const headers = [
        "Name",
        "Paddle Number",
        "Cork Number",
        "Table Number",
        "Contact",
      ];
      const my_data = [];
      this.state.wine.map((wineItem, index) => {
        const phoneNumber = wineItem["Phone Number"] || ""; // Replace undefined or null with an empty string
        const email = wineItem["Email"] || ""; // Replace undefined or null with an empty string

        my_data.push({
          Name: wineItem["Name"],
          "Paddle Number": wineItem["Paddle Number"],
          "Cork Number": wineItem["Cork Number"],
          "Table Number": wineItem["Table Number"],
          Contact: `${phoneNumber}\n${email}`,
          "Checked": wineItem["hasPickedUp"]
        });

        return null;
      });

      const report = new Report(0, `Wine Report`, my_data, headers);
      reports.push(report);

      this.setState(
        {
          reports: reports,
          title: `${new Date().toISOString().split("T")[0]}_WineReport.pdf`,
        },
        () => {
          this.createReports();
        }
      );
    });
  };

  render() {
    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Pledge Report</span>
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.handleSubmit} className="form-validate">
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label">Pledge Amount</label>
                    <span className="contactform-input">
                      <Form.Control
                        id="amount"
                        as="select"
                        value={this.state.donationType}
                        onChange={this.handleDonationChange}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an option &#x2015;
                        </option>
                        {this.state.categories.map(category => (
                            <option key={category.amount} value={category.amount}>
                              {category.name}
                            </option>))}                        
                            
                            <option key="custom" value="custom">Custom Amount</option>
                      </Form.Control>
                    </span>
                  </p>

                  <p className="auth-submit">
                    <input
                      type="submit"
                      value="Download Pledge Report"
                      disabled={this.state.pdfWorking}
                    />
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Wine Report</span>
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form
                  onSubmit={this.handleWineSubmit}
                  className="form-validate"
                >
                  <p className="auth-submit">
                    <input
                      type="submit"
                      value="Download Wine Report"
                      disabled={this.state.pdfWorking}
                    />
                  </p>
                </form>
              </div>
            </div>
          </section>
          {this.state.pdfWorking && (<Spinner />)}
        </main>

        <div
          className="pdfLetterhead"
          id="pdfLetterhead"
          style={{
            backgroundImage: "url(" + RibonBackgroundBlue + ")",
          }}
        ></div>
        <img
          className="hospitalLogo"
          id="hospitalLogo"
          src={logo}
          alt="Logo" // Add a meaningful description here
        />

      </>
    );
  }
}

export default Reports;