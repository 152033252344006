import React, { Component } from "react";
import "../css/Attendee.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Handshake from "../img/handshake.png";
import Spinner from "../Spinner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import * as XLSX from "xlsx"; // Import xlsx library

class Raffle extends Component {
  constructor(props) {
    super(props);
    const isTest = window.location.hostname.includes("test.");

    this.state = {
      selectedAttendee: null,
      raffleData: [],
      raffleSummary: [],
      error: null,
      screenWidth: window.innerWidth,
      isLoading: false,
      intervalId: null, // Store the interval ID in state
      categories: [{ name: "All", value: "all" }],
      category: "all",
      search: "",
      titleSuffix: isTest ? <> [Test Staff Only]</> : <> [Staff Only]</>,
      raffleNumber: "",
      searchResult: null,
    };

    this.handleResize = this.handleResize.bind(this);
    this.findByRaffleNumber = this.findByRaffleNumber.bind(this);
  }

  componentDidMount() {
    this.fetchRaffle();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  removePrefix = (str, prefix) => {
    if (str.startsWith(prefix)) {
      return str.slice(prefix.length);
    }
    return str;
  };

  findByRaffleNumber(e) {
    e.preventDefault();
    const { raffleNumber, raffleSummary } = this.state;
    const result = raffleSummary.find((entry) =>
      entry.raffle_numbers.includes(raffleNumber)
    );

    if (result) {
      this.setState({ searchResult: result, error: null });
    } else {
      this.setState({ searchResult: null, error: "Raffle number not found" });
    }
  }

  async fetchRaffle() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/raffles`);
      const data = await response.json();

      // Process the data to aggregate by purchase_reference
      const raffleSummaryObject = data.reduce((acc, raffle) => {
        const {
          purchase_reference,
          firstname,
          lastname,
          email,
          phone_number,
          address_line_1,
          address_line_2,
          city,
          province,
          postal_code,
          country,
          raffle_option,
          raffle_number,
          foil_number,
        } = raffle;

        if (!acc[purchase_reference]) {
          acc[purchase_reference] = {
            purchase_reference,
            firstname,
            lastname,
            email,
            phone_number,
            address_line_1,
            address_line_2,
            city,
            province,
            postal_code,
            country,
            tickets: 0,
            raffle_numbers: [],
            foil_numbers: [],
          };
        }

        // Assuming each raffle_option represents one ticket
        acc[purchase_reference].tickets += 1;
        acc[purchase_reference].raffle_numbers.push(
          this.removePrefix(raffle_number, "RaffleNumber_")
        );
        acc[purchase_reference].foil_numbers.push(
          this.removePrefix(foil_number, "RaffleFoil-")
        );

        return acc;
      }, {});

      const raffleSummaryArray = Object.values(raffleSummaryObject);

      this.setState({ raffleSummary: raffleSummaryArray, raffleData: data });
    } catch (error) {
      console.error("Error fetching raffle:", error);
    }
  }

  handleResize() {
    this.setState({ screenWidth: window.innerWidth });
  }

  getAttendeeOptions = () => {
    const results = this.state.attendees.map((attendee) => ({
      value: attendee.attendee_id, // Use "attendee_id" instead of "id"
      label: `${attendee.attendee_firstname} ${attendee.attendee_lastname}`, // Update property names
    }));

    console.log(results);
    return results;
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
      // Handle any other logic you want to perform when Enter is pressed in this field
    }
  };

  downloadPDF = (url, filename) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    this.setState({ isLoading: false });
  };

  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ category: selectedValue });

    console.log(selectedValue);
  };

  exportData = () => {
    const { raffleData } = this.state;

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(raffleData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Raffle");

    // Generate a unique filename for the Excel file
    const filename = `raffle_${new Date().toISOString()}.xlsx`;

    // Save the Excel file and trigger download
    XLSX.writeFile(wb, filename);
  };

  render() {
    const { raffleSummary, screenWidth, searchResult } = this.state;
    const isIPad = screenWidth <= 1200;

    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <form className="form-validate">
              <p>
                <button
                  onClick={this.exportData}
                  disabled={this.state.isLoading}
                >
                  Export Data As Excel
                </button>
              </p>

              {searchResult && (
              <div className="search-result">
                <p className="contactform-field contactform-text">
                  <strong>Name:</strong> {searchResult.firstname} {searchResult.lastname}<br />
                  <strong>Email:</strong> {searchResult.email}<br />
                  <strong>Phone:</strong> {searchResult.phone_number}
                </p>
                {/* Add more fields as needed */}
              </div>
            )}

              <h1 className="main-ttl">
                <span>Find Owner By Raffle Number</span>
              </h1>
              <div className="auth-wrap">
                <div className="auth-col">
                  <p className="contactform-field contactform-text">
                    <label
                      htmlFor="Raffle_Number"
                      className="contactform-label"
                    >
                      Raffle Number:
                    </label>
                    <span className="contactform-input">
                      <input
                        type="text"
                        id="Raffle_Number"
                        name="Raffle_Number"
                        value={this.state.raffleNumber}
                        onChange={(e) =>
                          this.setState({ raffleNumber: e.target.value })
                        }
                        placeholder="Raffle Number"
                      />
                    </span>
                  </p>

                  <p className="auth-submit">
                    <button
                      onClick={this.findByRaffleNumber}
                      disabled={this.state.isLoading}
                    >
                      Search Raffle Number
                    </button>
                  </p>
                </div>
              </div>
            </form>
            {this.state.error && (
              <div className="err404">
                <p className="err404-search">{this.state.error}</p>
              </div>
            )}

          </section>

          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>List of Purchased Raffle Tickets</span>
              {this.state.titleSuffix}
            </h1>
            <span className="keywordSearch">
              <input
                type="text"
                id="keywordSearch"
                name="keywordSearch"
                value={this.state.search}
                onChange={(e) => {
                  this.setState({ search: e.target.value });
                }}
                placeholder="Name Filter"
              />
            </span>

            <div className="cart-items-wrap">
              <table className="cart-items">
                <thead>
                  <tr>
                    <td className="cart-image"></td>
                    <td className="cart-ttl">Contact</td>
                    <td className="cart-quantity">
                      Number of Tickets Purchased
                    </td>
                    <td className="cart-ttl" style={{ textAlign: "right" }}>
                      Raffle Numbers
                    </td>
                    <td className="cart-ttl">Foil Numbers</td>
                  </tr>
                </thead>
                <tbody>
                  {raffleSummary
                    .slice()
                    .sort((a, b) => b.raffle_id - a.raffle_id) // Sort attendees by attendee_id in descending order
                    .map((result, index) => (
                      <tr key={index}>
                        <td className="cart-image">
                          <a href="product.html">
                            <img src={Handshake} alt="Handshake" />
                          </a>
                        </td>
                        <td className="cart-ttl">
                          {result["firstname"]} {result["lastname"]} <br />
                          <br />
                          {result["address_line_1"]}
                          <br />
                          {result["address_line_2"] && (
                            <>
                              {result["address_line_2"]}
                              <br />
                            </>
                          )}
                          {result["city"]}, {result["province"]}{" "}
                          {result["postal_code"]}
                          <br />
                          {result["phone_number"]}
                          <br />
                          {result["email"]}
                        </td>

                        <td className="cart-quantity">{result["tickets"]}</td>

                        <td
                          className="cart-quantity"
                          style={{ textAlign: "right" }}
                        >
                          {result["raffle_numbers"].map((number, index) => (
                            <React.Fragment key={index}>
                              {number}
                              {index < result["raffle_numbers"].length - 1 && (
                                <hr
                                  style={{
                                    border: "0",
                                    borderTop: "1px solid #ababab",
                                    margin: "4px 0",
                                  }}
                                />
                              )}
                            </React.Fragment>
                          ))}
                        </td>

                        <td className="cart-quantity">
                          {result["foil_numbers"].map((number, index) => (
                            <React.Fragment key={index}>
                              {number}
                              {index < result["foil_numbers"].length - 1 && (
                                <hr
                                  style={{
                                    border: "0",
                                    borderTop: "1px solid #ababab",
                                    margin: "4px 0",
                                  }}
                                />
                              )}
                            </React.Fragment>
                          ))}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
          {this.state.isLoading && <Spinner />}
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default Raffle;