import React, { Component } from "react";
import "../css/Attendee.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Handshake from "../img/handshake.png";
import Spinner from "../Spinner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import * as XLSX from "xlsx"; // Import xlsx library
import Select from "react-select"; // Import Select from react-select

const SEARCH_DROPDOWN_STYLE = {
  control: (baseStyles, state) => {
    return {
      ...baseStyles,
      borderWidth: "0px",
      minHeight: "0px",
      boxShadow: "0px",
    };
  },
  menu: (baseStyles, state) => {
    return {
      ...baseStyles,
      marginTop: "0px",
      marginBottom: "0px",
      boxShadow: "0px",
      fontSize: "0.9rem",
    };
  },
  option: (baseStyles, state) => {
    return {
      ...baseStyles,
      backgroundColor: state.isFocused ? "#DEEBFF" : "white",
      color: "black",
    };
  },
};

class Attendees extends Component {
  constructor(props) {
    super(props);
    const isTest= window.location.hostname.includes('test.');

    this.state = {
      selectedAttendee: null,
      attendees: [],
      error: null,
      screenWidth: window.innerWidth,
      isLoading: false,
      intervalId: null, // Store the interval ID in state
      categories: [
        { name: "All", value: "all" },
        { name: "Incomplete Registrations", value: "incomplete" }
      ],
      category: "all",
      search: "",
      titleSuffix: isTest ? <> [Test Staff Only]</> :<> [Staff Only]</>
    };

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.fetchAttendees();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  async fetchAttendees() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/registrations`
      );
      let data = await response.json();
      data = data.filter(
        (attendee) =>
          attendee.attendee_id !== 470 && attendee.attendee_firstname !== null
      );
      this.setState({ attendees: data });
    } catch (error) {
      console.error("Error fetching attendees:", error);
    }
  }

  handleResize() {
    this.setState({ screenWidth: window.innerWidth });
  }

  getAttendeeOptions = () => {
    const results= this.state.attendees.map((attendee) => ({
      value: attendee.attendee_id, // Use "attendee_id" instead of "id"
      label: `${attendee.attendee_firstname} ${attendee.attendee_lastname}`, // Update property names
    }));

    console.log(results);
    return results;
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
      // Handle any other logic you want to perform when Enter is pressed in this field
    }
  };

  downloadPDF = (url, filename) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    this.setState({ isLoading: false });
  };


  triggerDownloadSingleTicket = (e, data) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const payload = {
      attendee_id: data["attendee_id"],
      firstname: data["attendee_firstname"],
      lastname: data["attendee_lastname"],
      barcode: data["barcode"],
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/trigger-ticket`,
      data: payload, // Include payload in the request body
    }).then((response) => {
      const result = response.data;
      const url = result["url"];
      const filename = result["filename"]

      this.downloadPDF(url, filename);
    });
  };

  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ category: selectedValue });

    console.log(selectedValue);
  };



  exportData = () => {
    const { attendees } = this.state;

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert attendees data to worksheet
    const ws = XLSX.utils.json_to_sheet(attendees);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Attendees");

    // Generate a unique filename for the Excel file
    const filename = `attendees_${new Date().toISOString()}.xlsx`;

    // Save the Excel file and trigger download
    XLSX.writeFile(wb, filename);
  };

  render() {
    const { attendees, screenWidth } = this.state;
    const isIPad = screenWidth <= 1200;

    return (
      <>
        <LogoHeader />
        <main>
        <section className="container stylization maincont">
            <div className="auth-wrap">
              <div className="auth-col">
                <form className="form-validate">
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label">Categories</label>
                    <span className="contactform-input">
                      <Form.Control
                        id="amount"
                        as="select"
                        value={this.state.category}
                        onChange={this.handleOptionChange}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an option &#x2015;
                        </option>
                        {this.state.categories.map((category) => (
                          <option key={category.value} value={category.value}>
                            {category.name}
                          </option>
                        ))}
                      </Form.Control>
                    </span>
                  </p>

                  <p>
                    <button
                      onClick={this.exportData}
                      disabled={this.state.isLoading}
                    >
                      Export Data As Excel
                    </button>
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>List of Registered Tickets</span>{this.state.titleSuffix}
            </h1>
            <span className="keywordSearch">
            <input
                      type="text"
                      id="keywordSearch"
                      name="keywordSearch"
                      value={this.state.search}
                      onChange={e => {this.setState({ search: e.target.value });}}
                      placeholder="Name Filter"
                    />
            </span>




            <div className="cart-items-wrap">
              <table className="cart-items">
                <thead>
                  <tr>
                    <td className="cart-image"></td>
                    <td className="cart-ttl">Contact</td>
                    <td className="cart-quantity">
                      Is Registration Complete?
                    </td>
                    <td className="cart-quantity">Dietary Restrictions</td>
                    <td className="cart-quantity">Apple Wallet Pass Status</td>
                    <td className="cart-quantity">Download PDF Ticket</td>
                  </tr>
                </thead>
                <tbody>
                  {attendees
                    .slice()
                    .filter(result => {
                      if (this.state.category === "all") {
                        return true;
                      }


                      if (this.state.category === "incomplete") {
                        if (result.isRegistrationComplete === 0) {
                          return true;
                        }
                      }

                      return false;

                    }).filter(result => {
                      if (this.state.search !== null && this.state.search.trim() !== '') {
                        const searchQuery = this.state.search.trim().toLowerCase();
                        const fullName = `${result.attendee_firstname} ${result.attendee_lastname}`.toLowerCase();
                        return fullName.includes(searchQuery);
                      }

                      return true;
                    })
                    .sort((a, b) => b.attendee_id - a.attendee_id) // Sort attendees by attendee_id in descending order
                    .map((result, index) => (
                      <tr key={index}>
                        <td className="cart-image">
                          <a href="product.html">
                            <img src={Handshake} alt="Handshake" />
                          </a>
                        </td>
                        <td className="cart-ttl">
                          {result["attendee_firstname"]}{" "}
                          {result["attendee_lastname"]}{" "}
                          {result["isPrimary"] === 1 && (
                            <span style={{ color: "#fcb61c" }}>[PRIMARY]</span>
                          )}
                          <br />
                          <br />
                          {result["address_line_1"]}
                          <br />
                          {result["address_line_2"] && (
                            <>
                              {result["address_line_2"]}
                              <br />
                            </>
                          )}
                          {result["city"]}, {result["province"]}{" "}
                          {result["postal_code"]}
                          <br />
                          {result["attendee_phone_number"]
                            ? result["attendee_phone_number"]
                            : result["registration_phone_number"]}
                          <br />
                          {result["email"]}
                          {this.state.category === "transfer" && <>
                          <br />
                          <br />
                          <span style={{color: "red"}}>Ticket originally transferred from {result["original_attendee_name"]}</span>
                          </>}
                        </td>
                        <td className="cart-quantity">
                          {result["isRegistrationComplete"] === 1
                            ?                           <a
                            href={`https://tickets.bigsisters.bc.ca/register/${result["url_query"]}`}
                            target="_blank"
                          >
                            Yes
                          </a>
                            : <a
                            href={`https://tickets.bigsisters.bc.ca/register/${result["url_query"]}`}
                            target="_blank"
                          >
                            No, click here to access registration page
                          </a>}

                        </td>

                        <td className="cart-ttl">{result["dietary"]}</td>
                        <td className="cart-quantity">
                          {result["appleWalletStatus"] && result["isRegistrationComplete"] === 1
                            ? result["appleWalletStatus"]
                                .replace(/_/g, " ")
                                .toLowerCase()
                                .split(" ")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")
                            : null}
                        </td>
                        {result["isRegistrationComplete"] === 1 && result["barcode"] !== null ? (
                          <td className="cart-del">
                            <a
                              style={{ marginLeft: "50px" }}
                              className="cart-download"
                              href="/"
                              onClick={(e) => {
                                this.triggerDownloadSingleTicket(e, result);
                              }}
                              role="button" // Add role="button" to indicate it's a button
                            >
                              {"\u00A0"}
                            </a>
                          </td>
                        ) : (
                          <td className="cart-ttl"></td>
                        )}

                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
          {this.state.isLoading && <Spinner />}
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default Attendees;