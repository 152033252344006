import autoTable from 'jspdf-autotable';

function getDataForReport(data) {
  const results = data.map((row) => {
    const values = Object.values(row);
    values.unshift("");
    return values;
  });

  return results;
}

function drawUnchecked(data, pdf) {
  const textPos = data.cell.getTextPos();
  pdf.setDrawColor(164, 188, 194);
  pdf.setLineWidth(0.25);
  pdf.rect(textPos.x, textPos.y, 4, 4, 'D');
}

function drawCheckedBox(data, pdf) {
  const textPos = data.cell.getTextPos();
  const checkboxSize = 4; // Adjust the size as needed

  drawUnchecked(data, pdf);

  pdf.setLineWidth(1);

  // Set draw color to green (or the color you prefer) for the checkmark
  pdf.setDrawColor(0, 128, 0); // Green color

  // Draw the checkmark lines in green
  pdf.line(
    textPos.x + 0.5, textPos.y + 1,
    textPos.x + 1.5, textPos.y + checkboxSize - 1
  );

  pdf.line(
    textPos.x + 1.5, textPos.y + checkboxSize - 1,
    textPos.x + checkboxSize - 0.5, textPos.y + 0.5
  );
}



function addChecklist(pdf, report) {
  console.log(report);

  const headers = [...report.headers];
  headers.unshift("");

  autoTable(pdf, {
    head: [headers],
    body: getDataForReport(report.data), // Make sure you have a function getDataForReport that returns the report data
    headStyles: {
      fillColor: [164, 188, 194],
      textColor: "white",
      fontSize: 12,
      fontStyle: "bold",
    },
    styles: { cellPadding: { top: 3, bottom: 3 } },
    startY: 48,
    useCss: false,
    margin: { left: 5, right: 5 },
    columnStyles: {
      0: { cellWidth: 15 },
      1: { cellWidth: 50 },
    },
    didParseCell: function (data) {
      if (data.column.index === 0) {
        const currentPadding = Object.assign({}, data.cell.styles.cellPadding);
        currentPadding.top = 3;
        currentPadding.left = 3;
        data.cell.styles.cellPadding = currentPadding;
      }


    },
    didDrawCell: function (data) {
      if (data.cell.section === 'body' && data.column.index === 0) {
        const rowData = report.data[data.row.index];
        const isChecked = rowData.hasOwnProperty("Checked") ? rowData["Checked"] : false;

        if (isChecked) {
          drawCheckedBox(data, pdf);
        } else {
          drawUnchecked(data, pdf);
        }
        
      }

      if (data.row.index !== 0) {
        pdf.setDrawColor(164, 188, 194);
        pdf.setLineWidth(0.25);
        pdf.line(data.cell.x, data.cell.y, data.cell.x + data.cell.width, data.cell.y);
      }
    },
  });
}

export default addChecklist;