import React, { useState, useEffect } from "react";
import "../css/SummaryText.css";
import * as XLSX from "xlsx"; // Import xlsx library

const SummaryText = () => {
  const [ticketPurchases, setTicketPurchases] = useState([]);
  const [registrations, setRegistrations] = useState([]);

  useEffect(() => {
    const fetchTicketData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/tickets-data`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setTicketPurchases(data);
      } catch (error) {
        console.error("Error fetching ticket data:", error);
      }
    };

    const fetchRegistrations = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/registration-status`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setRegistrations(data);
      } catch (error) {
        console.error("Error fetching ticket data:", error);
      }
    };

    fetchTicketData();
    fetchRegistrations();
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  const formatDate = (dateString) => {
    const date = new Date(dateString + "Z");
    const options = {
      timeZone: "America/Los_Angeles", // Pacific Time Zone
      hour12: true, // Use 12-hour clock format
      weekday: "long", // Full day of the week
      year: "numeric", // Full year
      month: "long", // Full month name
      day: "numeric", // Day of the month
      hour: "numeric", // Hour (0-23)
      minute: "numeric", // Minute
      second: "numeric", // Second
    };
    return date.toLocaleString("en-US", options);
  };

  const exportData = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    const updatedTicketPurchases = ticketPurchases.map(purchase => {
      let ticketsReceived = parseInt(purchase.ticketQty);
      let itemPurchase = purchase.ticketOption;
      if (purchase.ticketOption.includes('table')) {
        itemPurchase = "Table"
        ticketsReceived = ticketsReceived * 10;
      } 
      else if (purchase.ticketOption.includes('corporate')) {
        itemPurchase = "Corporate Table"
        ticketsReceived = ticketsReceived * 10;
      }
      else if (purchase.ticketOption.includes('ambassador')) {
        itemPurchase = "Ambassador Table"
        ticketsReceived = ticketsReceived * 10;
      }
      else if (purchase.ticketOption.includes('ticket')) {
        itemPurchase = "Ticket"
      }

      let dietaryRestrictions = '';
      if (typeof purchase.dietary === 'string') {
        dietaryRestrictions = purchase.dietary;
      }

      return ({
        'ID': purchase.ID,
        'Purchase Date': formatDate(purchase.CreateDate),
        'Item Purchased': itemPurchase,
        'Quantity Purchased': purchase.ticketQty,
        'Number of Ticket(s) Received': ticketsReceived,
        'Amount Paid': parseInt(purchase.originalAmount).toFixed(2),
        'Organization': purchase.organizationName,
        'First Name': purchase.firstName,
        'Last Name': purchase.lastName,
        'Dietary Restrictions': dietaryRestrictions,
        'Address Line 1': purchase.addressLine1,
        'Address Line 2': purchase.addressLine2,
        'City': purchase.city,
        'Province': purchase.province,
        'Country': purchase.country,
        'Postal Code': purchase.zip,
        'Email': purchase.email,
        'Phone Number': purchase.phoneNumber,
        'Purchase URL': purchase.originURL,
        'Paid Fees?': purchase.payFees ? "Yes": "No",
        'Saved Payment Info?': purchase.saveInfo ? "Yes": "No",
        'Registration URL': `https://ticket.bigsisters.bc.ca/register/${purchase.url_query}`,
        'Logo URL': !purchase.filename? '': `https://diana-task-files.s3.ca-central-1.amazonaws.com/${purchase.filename}`,
        'Receipt Number': purchase.ReceiptNumber,
        'Stripe Payment Reference': purchase.paymentMethodId,
        'Reciept Reference': purchase.reference,
        'Additional Details': purchase.moreNeeds ? purchase.moreNeeds: "",
        'Billing Address 1': !purchase.billingAddress ? purchase.billingAddressLine1 : "",
        'Billing Address 2': !purchase.billingAddress ? purchase.billingAddressLine2 : "",
        'Billing City': !purchase.billingAddress ? purchase.billingCity : "",
        'Billing Province': !purchase.billingAddress ? purchase.billingProvince : "",
        'Billing Country': !purchase.billingAddress ? purchase.billingCountry : "",
        'Billing Postal Code': !purchase.billingAddress ? purchase.billingZip : "",
      });
    }
   );

    // Convert attendees data to worksheet
    const ws = XLSX.utils.json_to_sheet(updatedTicketPurchases);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Attendees");

    // Generate a unique filename for the Excel file
    const filename = `ticket_sales_${new Date().toISOString()}.xlsx`;

    // Save the Excel file and trigger download
    XLSX.writeFile(wb, filename);
  };

  const getRegistrationStatus = (url_query) => {
    const matchingRecords = registrations.filter(reg => reg.url_query === url_query);
    
    if (matchingRecords.length === 0) {
      return false; // No matching records found
    }
  
    return matchingRecords.every(reg => reg.isRegistrationComplete);
  }
  

  return (
    <div className="summary-text">
      <p>
        <button onClick={exportData} style={{background:"#fcb61c", fontWeight: "1000"}}>Export Data As Excel</button>
      </p>
      <table>
        <thead>
          <tr>
            <th>Buyer Name</th>
            <th>Purchase Date</th>
            <th>Number of Tickets Purchased</th>
            <th>Total Amount</th>
            <th>All Tickets Registered?</th>
          </tr>
        </thead>
        <tbody>
          {ticketPurchases
            .slice()
            .sort((a, b) => new Date(b.CreateDate) - new Date(a.CreateDate))
            .map((purchase) => (
              <tr key={purchase.ID}>
                <td>
                  {purchase.firstName} {purchase.lastName}
                </td>
                <td>{formatDate(purchase.CreateDate)}</td>
                <td>{purchase.ticketOption.includes("table") || purchase.ticketOption.includes("corporate") || purchase.ticketOption.includes("ambassador") ? purchase.ticketQty*10 : purchase.ticketQty}</td>
                <td>${parseInt(purchase.originalAmount).toFixed(2)} {purchase.ID === "9ec651b2-97bd-4c8e-b49a-5fb7e4c21a77"? <span style={{color:"red"}}>[Refunded]</span>: <></>}</td>
                <td>{getRegistrationStatus(purchase.url_query) ? "Yes" : "No"}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SummaryText;