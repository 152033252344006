import React, { Component } from "react";
import Spinner from "../Spinner"; // Include your Spinner component

class GiftBatches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      giftBatches: [],
      newBatch: {
        constituentName: "Example",
        payMethod: "Other",
        referenceNumber: "2023 Soiree sponsorship",
        referenceDate: "Aug 1, 2023",
        amount: "$12,000",
        receiptAmount: "None",
        reference: "Canada Helps",
        date: "Aug 1, 2023",
        glPostDate: "Aug 1, 2023",
        type: "Cash",
        campaign: "23-LAS Spo",
        fund: "Event",
        appeal: "UNSOL",
        acknowledge: "Not Acknowledged",
        receipt: "Not Receipted",
        receiptStack: "Business",
        letterCode: "None",
        glPostStatus: "Not Posted",
        anonymous: "No",
      },
      error: null,
      isLoading: false,
      screenWidth: window.innerWidth,
    };
  }

  addBatch = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { newBatch, giftBatches } = this.state;

    // Validation logic for required fields goes here
    // ...

    // Add the new batch to the state
    const updatedBatches = [...giftBatches, newBatch];
    this.setState({
      giftBatches: updatedBatches,
      newBatch: {
        constituentName: "Example",
        payMethod: "Other",
        referenceNumber: "2023 Soiree sponsorship",
        referenceDate: "Aug 1, 2023",
        amount: "$12,000",
        receiptAmount: "None",
        reference: "Canada Helps",
        date: "Aug 1, 2023",
        glPostDate: "Aug 1, 2023",
        type: "Cash",
        campaign: "23-LAS Spo",
        fund: "Event",
        appeal: "UNSOL",
        acknowledge: "Not Acknowledged",
        receipt: "Not Receipted",
        receiptStack: "Business",
        letterCode: "None",
        glPostStatus: "Not Posted",
        anonymous: "No",
      },
      error: null,
      isLoading: false,
    });
  };

  render() {
    const { giftBatches, newBatch, error, isLoading, screenWidth } = this.state;

    return (
      <>
        {/* Your existing JSX structure */}
        <main>
          <section className="container stylization maincont">
            {/* Gift Batch Form */}
            <h1 className="main-ttl">
              <span>Add a Gift Batch [Prototype]</span>
            </h1>
            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.addBatch} className="login">
                  {/* Render your form inputs here */}
                  {/* Example: */}
                  <p>
                    <label htmlFor="constituentName">
                      Constituent Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="constituentName"
                      name="Constituent Name"
                      value={newBatch.constituentName}
                      onChange={(e) =>
                        this.setState({
                          newBatch: { ...newBatch, constituentName: e.target.value },
                        })
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="payMethod">
                      Pay Method <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="payMethod"
                      name="Pay Method"
                      value={newBatch.payMethod}
                      onChange={(e) =>
                        this.setState({
                          newBatch: { ...newBatch, payMethod: e.target.value },
                        })
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="referenceNumber">
                      Reference Number
                    </label>
                    <input
                      type="text"
                      id="referenceNumber"
                      name="Reference Number"
                      value={newBatch.referenceNumber}
                      onChange={(e) =>
                        this.setState({
                          newBatch: { ...newBatch, referenceNumber: e.target.value },
                        })
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="referenceDate">
                      Reference Date
                    </label>
                    <input
                      type="text"
                      id="referenceDate"
                      name="Reference Date"
                      value={newBatch.referenceDate}
                      onChange={(e) =>
                        this.setState({
                          newBatch: { ...newBatch, referenceDate: e.target.value },
                        })
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="amount">
                      Amount <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="amount"
                      name="Amount"
                      value={newBatch.amount}
                      onChange={(e) =>
                        this.setState({
                          newBatch: { ...newBatch, amount: e.target.value },
                        })
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="receiptAmount">
                      Receipt Amount <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="receiptAmount"
                      name="Receipt Amount"
                      value={newBatch.receiptAmount}
                      onChange={(e) =>
                        this.setState({
                          newBatch: { ...newBatch, receiptAmount: e.target.value },
                        })
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="reference">
                    Reference
                    </label>
                    <input
                      type="text"
                      id="reference"
                      name="Reference"
                      value={newBatch.reference}
                      onChange={(e) =>
                        this.setState({
                          newBatch: { ...newBatch, reference: e.target.value },
                        })
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="date">
                        Date
                    </label>
                    <input
                        type="text"
                        id="date"
                        name="Date"
                        value={newBatch.date}
                        onChange={(e) =>
                        this.setState({
                            newBatch: { ...newBatch, date: e.target.value },
                        })
                        }
                    />
                    </p>
                    <p>
                    <label htmlFor="glPostDate">
                        GL Post Date
                    </label>
                    <input
                        type="text"
                        id="glPostDate"
                        name="GL Post Date"
                        value={newBatch.glPostDate}
                        onChange={(e) =>
                        this.setState({
                            newBatch: { ...newBatch, glPostDate: e.target.value },
                        })
                        }
                    />
                    </p>
                    <p>
                    <label htmlFor="type">
                        Type
                    </label>
                    <input
                        type="text"
                        id="type"
                        name="Type"
                        value={newBatch.type}
                        onChange={(e) =>
                        this.setState({
                            newBatch: { ...newBatch, type: e.target.value },
                        })
                        }
                    />
                    </p>
{/* Continuing with the remaining input fields */}
<p>
  <label htmlFor="campaign">
    Campaign
  </label>
  <input
    type="text"
    id="campaign"
    name="Campaign"
    value={newBatch.campaign}
    onChange={(e) =>
      this.setState({
        newBatch: { ...newBatch, campaign: e.target.value },
      })
    }
  />
</p>
<p>
  <label htmlFor="fund">
    Fund
  </label>
  <input
    type="text"
    id="fund"
    name="Fund"
    value={newBatch.fund}
    onChange={(e) =>
      this.setState({
        newBatch: { ...newBatch, fund: e.target.value },
      })
    }
  />
</p>
<p>
  <label htmlFor="appeal">
    Appeal
  </label>
  <input
    type="text"
    id="appeal"
    name="Appeal"
    value={newBatch.appeal}
    onChange={(e) =>
      this.setState({
        newBatch: { ...newBatch, appeal: e.target.value },
      })
    }
  />
</p>
<p>
  <label htmlFor="acknowledge">
    Acknowledge
  </label>
  <input
    type="text"
    id="acknowledge"
    name="Acknowledge"
    value={newBatch.acknowledge}
    onChange={(e) =>
      this.setState({
        newBatch: { ...newBatch, acknowledge: e.target.value },
      })
    }
  />
</p>
<p>
  <label htmlFor="receipt">
    Receipt
  </label>
  <input
    type="text"
    id="receipt"
    name="Receipt"
    value={newBatch.receipt}
    onChange={(e) =>
      this.setState({
        newBatch: { ...newBatch, receipt: e.target.value },
      })
    }
  />
</p>
<p>
  <label htmlFor="receiptStack">
    Receipt Stack
  </label>
  <input
    type="text"
    id="receiptStack"
    name="Receipt Stack"
    value={newBatch.receiptStack}
    onChange={(e) =>
      this.setState({
        newBatch: { ...newBatch, receiptStack: e.target.value },
      })
    }
  />
</p>
<p>
  <label htmlFor="letterCode">
    Letter Code
  </label>
  <input
    type="text"
    id="letterCode"
    name="Letter Code"
    value={newBatch.letterCode}
    onChange={(e) =>
      this.setState({
        newBatch: { ...newBatch, letterCode: e.target.value },
      })
    }
  />
</p>
<p>
  <label htmlFor="glPostStatus">
    GL Post Status
  </label>
  <input
    type="text"
    id="glPostStatus"
    name="GL Post Status"
    value={newBatch.glPostStatus}
    onChange={(e) =>
      this.setState({
        newBatch: { ...newBatch, glPostStatus: e.target.value },
      })
    }
  />
</p>
<p>
  <label htmlFor="anonymous">
    Anonymous
  </label>
  <input
    type="text"
    id="anonymous"
    name="Anonymous"
    value={newBatch.anonymous}
    onChange={(e) =>
      this.setState({
        newBatch: { ...newBatch, anonymous: e.target.value },
      })
    }
  />
</p>

                  {/* Repeat similar blocks for other fields */}
                  {/* Submit Button */}
                  <p className="auth-submit">
                    <input type="submit" value="Add Gift Batch" disabled={isLoading} />
                  </p>
                </form>
                {/* Display error message if any */}
                {error && (
                  <div className="err404">
                    <p className="err404-search">{error}</p>
                  </div>
                )}
              </div>
              <div className="auth-col"></div>
            </div>
          </section>

          {/* List of Gift Batches */}
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>List of Gift Batches</span>
            </h1>
            <div className="cart-items-wrap">
              <table className="cart-items">
              <thead>
  <tr>
    <th>Constituent Name</th>
    <th>Pay Method / Reference Number</th>
    <th>Reference Date</th>
    <th>Amount / Receipt Amount</th>
    <th>Reference</th>
    <th>Date / GL Post Date</th>
    <th>Type</th>
    <th>Campaign / Fund / Appeal</th>
    <th>Acknowledge / Receipt</th>
    <th>Receipt Stack / Letter Code / GL Post Status</th>
    <th>Anon?</th>
  </tr>
</thead>

                <tbody>
                  {/* Iterate over giftBatches and display batch details */}
                  {giftBatches.map((batch, index) => (
  <tr key={index}>
    <td>{batch.constituentName}</td>
    <td>{batch.payMethod}<br/>{batch.referenceNumber}</td>
    <td>{batch.referenceDate}</td>
    <td>{batch.amount}<br/>{batch.receiptAmount}</td>
    <td>{batch.reference}</td>
    <td>{batch.date}<br/>{batch.glPostDate}</td>
    <td>{batch.type}</td>
    <td>{batch.campaign}<br/>{batch.fund}<br/>{batch.appeal}</td>
    <td>{batch.acknowledge}<br/>{batch.receipt}</td>
    <td>{batch.receiptStack}<br/>{batch.letterCode}<br/>{batch.glPostStatus}</td>
    <td>{batch.anonymous}</td>
  </tr>
))}

                </tbody>
              </table>
            </div>
          </section>

          {/* Display spinner when loading */}
          {isLoading && <Spinner />}
        </main>
      </>
    );
  }
}

export default GiftBatches;
