import React, { Component } from "react";
import Spinner from "../Spinner";
import "../css/RaffleDisplay.css"; // Create a separate CSS file for styling
import overlayImage from "../img/foreground.png"; // Import your PNG image

class RaffleDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      isVisible: false,
      dataList: [],
      amount: 10000,
      display: "",
      isLoading: true
    };
  }

  componentDidMount() {
    const checkForImage = () => {
      const overlayImageElement = document.getElementById('OverlayImage');
  
      const imageLoadHandler = () => {
        this.setState({ isLoading: false }, () => {
          this.updateScreen();
  
          // Set up an interval to call the API every 4 seconds
          this.interval = setInterval(this.updateScreen, 4000);
        });
  
        // Remove the event listener after it has been triggered
        overlayImageElement.removeEventListener('load', imageLoadHandler);
      };
  
      if (overlayImageElement) {
        // Check if the image has finished loading
        if (overlayImageElement.complete) {
          // If the image is already loaded, call the handler immediately
          imageLoadHandler();
        } else {
          overlayImageElement.addEventListener('load', imageLoadHandler);
        }
      } else {
        setTimeout(checkForImage, 1000); // Adjust the delay as needed
      }
    };
  
    checkForImage();
  }
  

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to prevent memory leaks
    clearInterval(this.interval);
    clearTimeout(this.timeoutId);
  }

  addLoadEventListeners() {
    // Check if the CSS link element exists
    const cssLink = document.querySelector('link[href*="RaffleDisplay.css"]');
    if (cssLink) {
      cssLink.onload = () => {
        // CSS has loaded, update isLoading
        this.setState({ isLoading: false });
      };
    } else {
      console.error("CSS link not found");
    }

    // Create a new image element for the overlay image
    const overlayImg = new Image();
    overlayImg.onload = () => {
      // Image has loaded, update isLoading
      this.setState({ isLoading: false });
    };
    overlayImg.src = overlayImage; // Set the source to trigger the image load

    // It's important to note that we handle the case where elements are not found.
  }

  updateScreen = () => {
    this.fetchData();
  };

  fetchData = () => {
    console.log("Fetching data!");

    fetch(`${process.env.REACT_APP_API_URL}/brenda/raffles`)
      .then((response) => response.json())
      .then((data) => {
        const displayObject = data.find((item) => item.field === 'Display');
        console.log(displayObject);
        this.setState({ display: displayObject['value'] });
      });
  };

  render() {
    return (
      <div className="background">
        <div className="raffle">
          <h1 className="name much-larger-font">
            {this.state.display}
          </h1>
          <div className="overlay">
            <img src={overlayImage} alt="Overlay" id="OverlayImage" />
          </div>
        </div>
        {this.state.isLoading && (<Spinner />)}
      </div>
    );
  }
}

export default RaffleDisplay;