import React from "react";
import LogoHeader from "./Soiree2023/LogoHeader"; // Import LogoHeader or specify the correct import path

const NotFound = () => {
  return (
    <>
      <LogoHeader /> {/* You should specify the correct import or component */}
      <main>
        <section className="container stylization maincont">
          <h1 className="main-ttl">
            <span>Not Found</span>
          </h1>
          <p>Sorry, the page you are looking for does not exist.</p>
        </section>
      </main>
    </>
  );
};

export default NotFound;
