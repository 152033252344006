import React, { Component } from "react";
import "../css/Attendee.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Handshake from "../img/handshake.png";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import * as XLSX from "xlsx"; // Import xlsx library

class Vouchers extends Component {
  constructor(props) {
    super(props);
    const isTest = window.location.hostname.includes("test.");

    this.state = {
      selectedAttendee: null,
      raffleData: [],
      raffleSummary: [],
      error: null,
      screenWidth: window.innerWidth,
      isLoading: false,
      intervalId: null, // Store the interval ID in state
      categories: [{ name: "All", value: "all" }],
      category: "all",
      search: "",
      titleSuffix: isTest ? <> [Test Staff Only]</> : <> [Staff Only]</>,
      firstname: "",
      lastname: "",
      email: "",
      data: []
    };

    this.handleResize = this.handleResize.bind(this);

  }

  componentDidMount() {
    this.fetchVouchers();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  removePrefix = (str, prefix) => {
    if (str.startsWith(prefix)) {
      return str.slice(prefix.length);
    }
    return str;
  };

  createVoucher = async (e) => {
    e.preventDefault();
    const { firstname, lastname, email } = this.state;

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/vouchers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ firstname, lastname, email })
        });

        const result = await response.json();

        if (result.success) {
          toast.success("Voucher has been created!")
          this.setState({firstname: "", lastname: "", email: ""});
            this.fetchVouchers();
        } else {
            this.setState({ error: "Unable to create voucher" });
        }
    } catch (error) {
        console.error("Error creating voucher:", error);
        this.setState({ error: "An error occurred while creating the voucher." });
    }
};


fetchVouchers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/vouchers`);
      const data = await response.json();


      this.setState({ data: data });
    } catch (error) {
      console.error("Error fetching raffle:", error);
    }
  }

  handleResize() {
    this.setState({ screenWidth: window.innerWidth });
  }

  getAttendeeOptions = () => {
    const results = this.state.attendees.map((attendee) => ({
      value: attendee.attendee_id, // Use "attendee_id" instead of "id"
      label: `${attendee.attendee_firstname} ${attendee.attendee_lastname}`, // Update property names
    }));

    console.log(results);
    return results;
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
      // Handle any other logic you want to perform when Enter is pressed in this field
    }
  };

  downloadPDF = (url, filename) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    this.setState({ isLoading: false });
  };

  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ category: selectedValue });

    console.log(selectedValue);
  };

  exportData = () => {
    const { data } = this.state;

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(data);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Vouchers");

    // Generate a unique filename for the Excel file
    const filename = `vouchers_${new Date().toISOString()}.xlsx`;

    // Save the Excel file and trigger download
    XLSX.writeFile(wb, filename);
  };

  render() {
    const { data, screenWidth } = this.state;
    const isIPad = screenWidth <= 1200;

    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <form className="form-validate">
              <p>
                <button
                  onClick={this.exportData}
                  disabled={this.state.isLoading}
                >
                  Export Data As Excel
                </button>
              </p>

              <h1 className="main-ttl">
                <span>Create a Voucher</span>
              </h1>
              <div className="auth-wrap">
                <div className="auth-col">
                  <p className="contactform-field contactform-text">
                    <label
                      htmlFor="firstname"
                      className="contactform-label"
                    >
                      Firstname:
                    </label>
                    <span className="contactform-input">
                      <input
                        type="text"
                        id="firstname"
                        name="firstname"
                        value={this.state.firstname}
                        onChange={(e) =>
                          this.setState({ firstname: e.target.value })
                        }
                        placeholder="Firstname"
                      />
                    </span>
                  </p>
                  <p className="contactform-field contactform-text">
                    <label
                      htmlFor="lastname"
                      className="contactform-label"
                    >
                      Lastname:
                    </label>
                    <span className="contactform-input">
                      <input
                        type="text"
                        id="lastname"
                        name="lastname"
                        value={this.state.lastname}
                        onChange={(e) =>
                          this.setState({ lastname: e.target.value })
                        }
                        placeholder="Lastname"
                      />
                    </span>
                  </p>

                  <p className="contactform-field contactform-text">
                    <label
                      htmlFor="email"
                      className="contactform-label"
                    >
                      Email:
                    </label>
                    <span className="contactform-input">
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={this.state.email}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                        placeholder="Email"
                      />
                    </span>
                  </p>

                  <p className="auth-submit">
                    <button
                      onClick={this.createVoucher}
                      disabled={this.state.isLoading}
                    >
                      Create Voucher
                    </button>
                  </p>
                </div>
              </div>
            </form>
            {this.state.error && (
              <div className="err404">
                <p className="err404-search">{this.state.error}</p>
              </div>
            )}

          </section>

          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>List of Issued Voucher Codes</span>
              {this.state.titleSuffix}
            </h1>

            <div className="cart-items-wrap">
              <table className="cart-items">
                <thead>
                  <tr>
                    <td className="cart-image"></td>
                    <td className="cart-ttl">Contact</td>
                    <td className="cart-quantity">Has been used?</td>
                    <td className="cart-ttl" style={{ textAlign: "right" }}>
                      Voucher
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {data.map((result, index) => (
                      <tr key={index}>
                        <td className="cart-image">
                          <a href="product.html">
                            <img src={Handshake} alt="Handshake" />
                          </a>
                        </td>
                        <td className="cart-ttl">
                          {result["voucher_firstname"]} {result["voucher_lastname"]} <br />
                          <br />
                          {result["voucher_email"]}
                        </td>

                        <td className="cart-quantity">{result["alreadyUsed"] ? "Yes": "No"}</td>

                        <td
                          className="cart-quantity"
                          style={{ textAlign: "right" }}
                        >
                          {result["code"]}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
          {this.state.isLoading && <Spinner />}
        </main>
        <ToastContainer />
      </>
    );
  }
}

export default Vouchers;