import React, { Component } from "react";
import "../css/Attendee.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Handshake from "../img/handshake.png";
import Spinner from "../Spinner";

class Attendees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attendees: [],
      newAttendee: {
        Name: "",
        "Phone Number": "",
        Email: "",
        "QR Code": "",
        "Paddle Number": "",
        "Table Number": "",
      },
      error: null,
      screenWidth: window.innerWidth,
      isLoading: false,
    };

    this.addAttendee = this.addAttendee.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this.fetchAttendees();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  async fetchAttendees() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/brenda/attendees`);
      const data = await response.json();
      this.setState({ attendees: data }, ()=> console.log(this.state));
    } catch (error) {
      console.error("Error fetching attendees:", error);
    }
  }

  hasEmptyFields(attendee) {
    for (const key in attendee) {
      if (attendee.hasOwnProperty(key)) {
        if (attendee[key] === "" || attendee[key] === null) {
          if (
            key === "Name" ||
            key === "Paddle Number" ||
            key === "QR Code" ||
            key === "Table Number"
          ) {
            return true; // Found an empty field
          }
        }
      }
    }
    return false; // No empty fields found
  }

  addAttendee(e) {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { newAttendee, attendees } = this.state;

    if (this.hasEmptyFields(newAttendee)) {
      this.setState({ error: "Missing required fields", isLoading: false });
      return;
    }

    const existingAttendee = attendees.find(
      (attendee) =>
        attendee["Paddle Number"] === newAttendee["Paddle Number"].toString() ||
        attendee["QR Code"] === newAttendee["QR Code"]
    );

    console.log(existingAttendee);

    if (existingAttendee) {
      this.setState({
        error: "An attendee with the same Paddle Number or QR Code already exists",
        isLoading: false
      });
      return;
    } else {
      this.setState({ error: ""});
    }

    fetch(`${process.env.REACT_APP_API_URL}/brenda/attendees`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newAttendee),
    })
      .then((response) => {
        if (response.ok) {
          const updatedAttendees = [...attendees, newAttendee];
          this.setState({
            attendees: updatedAttendees,
            newAttendee: {
              Name: "",
              "Phone Number": "",
              Email: "",
              "QR Code": "",
              "Paddle Number": "",
              "Table Number": "",
            },
          });
        } else {
          response.json().then(data => {
            console.log(data);
            this.setState({ error: data["Message"] });
          });
        }
      })
      .catch((error) => {
        this.setState({ error: `Error adding attendee: ${error.message}` });
        console.error("Error adding attendee:", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  toCamelCaseName(inputName) {
    const words = inputName.split(" ");
    const camelCaseName = words
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");

    return camelCaseName;
  }

  handleResize() {
    this.setState({ screenWidth: window.innerWidth });
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default form submission
      // Handle any other logic you want to perform when Enter is pressed in this field
    }
  };

  render() {
    const { attendees, newAttendee, error, isLoading, screenWidth } = this.state;
    const isIPad = screenWidth <= 1200;

    return (
      <>
        <LogoHeader />
        <main>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Add an Attendee</span>
            </h1>
            <div className="auth-wrap">
              <div className="auth-col">
                <form onSubmit={this.addAttendee} className="login">
                  <p>
                    <label htmlFor="name">
                      Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="Name"
                      value={newAttendee.Name}
                      onChange={(e) =>
                        this.setState({
                          newAttendee: { ...newAttendee, Name: e.target.value },
                        })
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="phone-number">Phone Number</label>
                    <input
                      type="text"
                      id="phone_number"
                      name="Phone Number"
                      value={newAttendee["Phone Number"]}
                      onChange={(e) =>
                        this.setState({
                          newAttendee: {
                            ...newAttendee,
                            "Phone Number": e.target.value,
                          },
                        })
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      id="email"
                      name="Email"
                      value={newAttendee["Email"]}
                      onChange={(e) =>
                        this.setState({
                          newAttendee: { ...newAttendee, Email: e.target.value },
                        })
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="qr_code">
                      QR Code <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="qr_code"
                      name="QR Code"
                      value={newAttendee["QR Code"]}
                      onChange={(e) =>
                        this.setState({
                          newAttendee: { ...newAttendee, "QR Code": e.target.value },
                        })
                      }
                      onKeyPress={this.handleKeyPress}
                    />
                  </p>
                  <p>
                    <label htmlFor="paddle_number">
                      Paddle Number <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="paddle_number"
                      name="Paddle Number"
                      value={newAttendee["Paddle Number"]}
                      onChange={(e) =>
                        this.setState({
                          newAttendee: {
                            ...newAttendee,
                            "Paddle Number": e.target.value,
                          },
                        })
                      }
                    />
                  </p>
                  <p>
                    <label htmlFor="table_number">
                      Table Number <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      id="table_number"
                      name="Table Number"
                      value={newAttendee["Table Number"]}
                      onChange={(e) =>
                        this.setState({
                          newAttendee: {
                            ...newAttendee,
                            "Table Number": e.target.value,
                          },
                        })
                      }
                    />
                  </p>
                  <p className="auth-submit">
                    <input type="submit" value="Add Attendee" disabled={isLoading} />
                  </p>
                </form>
                {error && (
                  <div className="err404">
                    <p className="err404-search">{error}</p>
                  </div>
                )}
              </div>
              <div className="auth-col"></div>
            </div>
          </section>
          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>List of Attendees</span>
            </h1>
            <div className="cart-items-wrap">
              <table className="cart-items">
                <thead>
                  <tr>
                    <td className="cart-image"></td>
                    <td className="cart-ttl">Name</td>
                    <td className="cart-quantity">Paddle Number</td>
                    <td className="cart-quantity">Table Number</td>
                    <td
                      className="cart-ttl"
                      style={{ textAlign: isIPad ? "right" : "left" }}
                    >
                      Contact
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {attendees
                    .slice()
                    .map((result, index) => (
                      <tr key={index}>
                        <td className="cart-image">
                          <a href="product.html">
                            <img src={Handshake} alt="Handshake" />
                          </a>
                        </td>
                        <td className="cart-ttl">
                          {this.toCamelCaseName(result["Name"])}
                        </td>
                        <td className="cart-quantity">
                          {result["Paddle Number"]}
                        </td>
                        <td className="cart-quantity">
                          {result["Table Number"]}
                        </td>
                        <td
                          className="cart-ttl"
                          style={{ textAlign: isIPad ? "right" : "left", width: "100%" }}
                        >
                          <p>
                            {result["Phone Number"]}{" "}
                            {result["Phone Number"] && !isIPad && (
                              <span className="small-grey-text">[Phone Number]</span>
                            )}
                          </p>
                          <p>
                            {result["Email"]}{" "}
                            {result["Email"] && !isIPad && (
                              <span className="small-grey-text">[Email]</span>
                            )}
                          </p>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
          {this.state.isLoading && (<Spinner />)}
        </main>
      </>
    );
  }
}

export default Attendees;