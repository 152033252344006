import React from "react";
import "../css/SummaryText.css"; // Create a separate CSS file for styling

class SummaryText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        {
          category: "Soiree",
          itemsSold: 4,
          grossSales: "$0.70",
        },
        {
          category: "Soiree 2023 - Raffle Tickets",
          itemsSold: 94,
          grossSales: "$3,830.00",
        },
        {
          category: "Soiree 2023 - Wine Wall",
          itemsSold: 136,
          grossSales: "$4,080.00",
        },
      ],
    };
  }

  render() {
    return (
      <div className="summary-text">
        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Items Sold</th>
              <th>Gross Sales</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((item, index) => (
              <tr key={index}>
                <td>{item.category}</td>
                <td>{item.itemsSold}</td>
                <td>{item.grossSales}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SummaryText;
